import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  storeToken,
  pkceRedirectLogin,
  verifyStateAndFetchToken,
  storeNextRouteInLocalStorage,
  getNextRouteFromLocalStorage,
  clearNextRouteFromLocalStorage,
  retrieveUserInfo,
} from "../../../services/oauth";
import { withLoginState } from "../components/LoginState";
import Spinner from "../../spinner/Spinner";
import AuthenticationError from "./AutheticationError";
import { isUserInGitLabEntgraCustomerSupportGroup } from "../../../services/gitlab";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isError: false,
      isRetry: true,
    };
  }
  componentDidMount() {
    let oauthConfig = this.props.oauthConfig;
    const urlQueryParams = new URLSearchParams(window.location.search);
    if (!oauthConfig.CLIENT_ID) {
      this.setState({
        isRetry: false,
        isError: true,
        errorDescription:
          "Make sure you have added Gitlab OAuth CLIENT_ID to .env file",
      });
      return;
    }
    if (!oauthConfig.REDIRECT_URI) {
      this.setState({
        isRetry: false,
        isError: true,
        errorDescription: "Make sure you have added REDIRECT_URI to .env file",
      });
      return;
    }
    if (urlQueryParams.has("error")) {
      this.setState({
        isError: true,
        errorDescription: urlQueryParams.get("error"),
      });
      return;
    }
    if (!urlQueryParams.has("code")) {
      this.login();
      return;
    }
    verifyStateAndFetchToken(oauthConfig, urlQueryParams)
      .then(async (tokenData) => {
        let userInfo;
        try {
          userInfo = await retrieveUserInfo(
            oauthConfig,
            tokenData.access_token
          );
          let isUserInGitLabEntgraCustomerSupport =
            await isUserInGitLabEntgraCustomerSupportGroup(
              tokenData.access_token
            );
          if (!isUserInGitLabEntgraCustomerSupport) {
            this.setState({
              isError: true,
              errorDescription:
                "You do not have access to GitLab customer support group of Entgra, Please contact your adminstrator ",
            });
            return;
          }
        } catch (e) {
          this.setState({
            isError: true,
            errorDescription:
              "Error occurred while trying retrieve gitlab user/group information",
          });
          return;
        }
        storeToken(tokenData);
        let next = getNextRouteFromLocalStorage();
        if (!next) {
          next = "/";
        }
        clearNextRouteFromLocalStorage();
        this.props.logIn(userInfo, tokenData, () =>
          this.props.history.push(next)
        );
      })
      .catch((e) => {
        if (e && e.response && e.response.status === 400) {
          pkceRedirectLogin(oauthConfig);
          return;
        }
        this.setState({
          isError: true,
          errorDescription:
            "Error occurred while requesting token. Retry Login",
        });
      });
  }
  login = () => {
    const { oauthConfig, next } = this.props;
    if (this.props.next) {
      storeNextRouteInLocalStorage(next);
    }
    pkceRedirectLogin(oauthConfig);
  };
  render() {
    const { isError, errorDescription, isRetry } = this.state;
    return isError ? (
      <AuthenticationError
        errorDescription={errorDescription}
        isRetry={isRetry}
        onRetry={this.login}
      />
    ) : (
      <Spinner />
    );
  }
}

const LoginWHistory = withRouter(Login);

export default withLoginState(LoginWHistory);
