import React, {Component} from 'react';
import "./InpWOCTable.css";
import config from "../../../resources/config/config.json";
import Functions from "../../../functions/Functions";
import SearchTable from '../../search/SearchTable';

/**
 @desc this component get the issues details from the parent component using props values and do the calculation
 and then fill the table with them.
 */
class InpWOCTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchInput: "",
        }
    }

    onSearch = (e) => {
        this.setState({searchInput: e.target.value.toLowerCase()})
    }

    /**
     * @desc get the props value from the parent component and get the specific data and then pass them to the table
     * @return table values with the structure
     */
    getInpWocTableData() {

        const {searchInput}= this.state
        const {Inp_Woc_table} = config;
        const table_list = [];
        //it gets the props values from the dashboard component
        const details = this.props.table_issues_details;

        //get the all issues details one by one
        details.forEach((detail) => {
            //get the each issue labels
            detail.labels.forEach((details_label) => {
                //get the data from the config file
                if (detail.title.toLowerCase().includes(searchInput)) {
                    Inp_Woc_table.search_status.forEach((search_label_data) => {
                        if (details_label === search_label_data.search_label) {
                            const filtered_data = {};
                            // check, whether the each issue has a assignee or not
                            if (detail.assignee === null) {
                                filtered_data['assignee'] = Inp_Woc_table.display_none_assignee;
                            } else {
                                filtered_data['assignee'] = detail.assignee.name;
                            }
                            table_list.push(Functions.get_issues_data(detail, filtered_data, search_label_data.show_label));
                        }
                    });
                }
            });
        });
        const rows = table_list.map((list, i) => (
            //map the list and set them to table
            <tr key={list.id + i}>
                <td><a target="_blank" rel="noopener noreferrer" href={list.url}>{list.customer_acc} #{list.iid}</a></td>
                <td>{list.title}</td>
                <td>{list.label}</td>
                <td>{list.assignee}</td>
                <td>{list.time}</td>
            </tr>
        ));

        const table_structure = (
            <thead>
            <tr>
                <th>Issue</th>
                <th>Title</th>
                <th>State</th>
                <th>Assignee</th>
                <th>Update Time</th>
            </tr>
            </thead>
        );
        //check the list is empty or not, if its not the value will return to the table
        if (table_list.length !== 0) {
            return (
                <table>
                    {table_structure}
                    <tbody>{rows}</tbody>
                </table>
            );
        } else {
            // return if the table body is empty
            return (
                <div>
                    <table>
                        {table_structure}
                    </table>
                    <div className="inp-woc-table-empty">
                        <h5>No Data</h5>
                    </div>
                </div>
            );
        }
    };

    render() {
        const {searchInput} = this.state;
        const inpWocTableData = this.getInpWocTableData();
        return (
            <div>
                <div className="inp-woc-table-title">
                    <h4>In Progress & W.O.C Issues</h4>
                </div>
                <SearchTable onSearch={this.onSearch} searchValue={searchInput} classNameList={["InpWOCTableSearch"]}/>
                <div className="InpWocTable">{inpWocTableData}</div>
            </div>
        );
    };
}

export default InpWOCTable;
