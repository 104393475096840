import axios from "axios";
import { getBearAuthorizationHeader } from "./oauth";
import config from "../resources/config/config.json";

export const API = (url, token, renewToken = null) => {
  let authorizationHeader;
  let headers;

  const init = (token) => {
    authorizationHeader = getBearAuthorizationHeader(token);
    headers = { headers: authorizationHeader };
  };

  init(token);

  const isErrResUnAuthorized = (err) => {
    return err && err.response && err.response.status === 401;
  };

  const retryGetRequest = async () => {
    return axios.get(url, headers);
  };

  const retryPostRequest = async (data) => {
    return axios.post(url, data, headers);
  };

  const get = async () => {
    return axios.get(url, headers).catch((err) => {
      return renewAndReTryRequestIfUnauthorized(err, renewToken, () => {
        return retryGetRequest();
      });
    });
  };
  const post = (data = null) => {
    return axios.post(url, data, headers).catch((err) => {
      renewAndReTryRequestIfUnauthorized(err, renewToken, () => {
        return retryPostRequest(data);
      });
    });
  };

  const renewAndReTryRequestIfUnauthorized = (
    err,
    renewToken,
    retryRequestFunc
  ) => {
    if (!isErrResUnAuthorized(err)) {
      throw err;
    }
    if (renewToken) {
      return renewToken().then((newToken) => {
        init(newToken);
        return retryRequestFunc();
      });
    }
    throw err;
  };

  return {
    get,
    post,
  };
};

export const retrieveGitLabEntgraCustomerSupportGroups = async (token) => {
  let authroizationHeader = getBearAuthorizationHeader(token);
  let res = await axios.get(
    config.gitlab_base_api +
      "/groups?search=" +
      config.entgra_customer_support_group_path,
    {
      headers: { ...authroizationHeader },
    }
  );
  return res.data;
};

export const isUserInGitLabEntgraCustomerSupportGroup = async (token) => {
  let groups = await retrieveGitLabEntgraCustomerSupportGroups(token);
  let entgraCustomerGroupExists = groups.find(
    (g) => g.path === config.entgra_customer_support_group_path
  );
  return !!entgraCustomerGroupExists;
};
