import React, {Component} from 'react';
import "./OtherTable.css";
import config from "../../../resources/config/config.json";
import Functions from "../../../functions/Functions";
import SearchTable from '../../search/SearchTable';

/**
 * @desc This component gets the issues details from the parent component using prop values and do the calculation
 and then fill the other table.
 */
class OtherTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchInput: "",
        }
    }

    onSearch = (e) => {
        this.setState({searchInput: e.target.value.toLowerCase()})
    }
    /**
     * @desc get the props value from the parent component and get the specific data and then pass them to the table
     * @return table values with the structure
     */
    getOtherTableData() {

        const {searchInput} = this.state;
        const {Other_table} = config;
        const table_list = [];
        const details = this.props.table_issues_details;

        details.forEach((detail) => {

            if (detail.title.toLowerCase().includes(searchInput)) {
            let other_search_array = Other_table.search_labels;
            let api_label_array = detail.labels;
            /*
            compare every issue label list and the other search list which in the config file
            it check whether the config other search list data is in the issue label list or not
             */
            const compare_arrays = api_label_array.some(element => other_search_array.includes(element));

            //check, if the arrays are not compared and the issue label list is not empty
            if (compare_arrays === false && detail.labels.length !== 0) {
                const filtered_data = {};
                //check, if the other issue has a assignee or not
                if (detail.assignee === null) {
                    filtered_data['assignee'] = Other_table.display_none_assignee;
                } else {
                    filtered_data['assignee'] = detail.assignee.name;
                }
                table_list.push(Functions.get_issues_data(detail, filtered_data, Other_table.display_state_name));
            }
        }
        });
        const rows = table_list.map((list) => (
            <tr key={list.id}>
                <td><a target="_blank" rel="noopener noreferrer" href={list.url}>{list.customer_acc} #{list.iid}</a></td>
                <td>{list.title}</td>
                <td>{list.label}</td>
                <td>{list.assignee}</td>
                <td>{list.time}</td>
            </tr>
        ));
        const table_structure = (
            <thead>
            <tr>
                <th>Issue</th>
                <th>Title</th>
                <th>State</th>
                <th>Assignee</th>
                <th>Update Time</th>
            </tr>
            </thead>
        );
        //check the list is empty or not, if its not the value will return to the table
        if (table_list.length !== 0) {
            return (
                <table>
                    {table_structure}
                    <tbody>{rows}</tbody>
                </table>
            );
        } else {
            return (
                <div>
                    <table>
                        {table_structure}
                    </table>
                    <div className="other-table-empty">
                        <h5>No Data</h5>
                    </div>
                </div>
            );
        }
    };

    render() {
        const {searchInput} = this.state;
        const otherTableData = this.getOtherTableData();
        return (
            <div>
                <div className="other-table-title">
                    <h4>Other Issues</h4>
                </div>
                <SearchTable onSearch={this.onSearch} searchValue={searchInput} classNameList={["otherTableSearch"]}/>
                <div className="other-table">{otherTableData}</div>
            </div>
        );
    }
}

export default OtherTable;
