import React from "react";
import { withLoginState } from "./LoginState";
import Login from "../scenes/Login";
import { useHistory } from "react-router-dom";
import { Route } from "react-router-dom";

const ProtectedRoute = ({ children, ...props }) => {
  const history = useHistory();
  const next = history.location.pathname;
  return props.tokenData ? (
    <Route {...props}>{children}</Route>
  ) : (
    <Login next={next} />
  );
};

export default withLoginState(ProtectedRoute);
