import React from "react";
import "./search-table.css";

const SearchTable = ({ onSearch, searchValue, classNameList }) => {
  console.log(classNameList)
  const defaultSearchClass = "search-container"
  classNameList = classNameList ? classNameList : []
  classNameList.push(defaultSearchClass)
  console.log(classNameList)
  const classNames = classNameList.join(" ")
  console.log(classNames)
  return (
    <div className={classNames}>
      <input
        className="search-input"
        type="text"
        id="oi-search"
        name="oi-search"
        onChange={onSearch}
        value={searchValue}
        placeholder="Search by title:"
        />
    </div>
  );
};

export default SearchTable;
