import React from "react";
import logo from "../../resources/images/logo.png";
import "./Header.css";

const Header = ({ userInfo }) => (
  <div
    className="header-bar"
    style={{
      display: "flex",
      paddingTop: "5px",
      paddingBottom: "5px",
      paddingRight: "3%",
      paddingLeft: "3%",
      alignItems: "center",
      justifyContent: "space-between",
      boxShadow: "0 2px 8px #f0f1f2",
      borderBottom: "1px solid #eee",
    }}
  >
    <img src={logo} height="50px" alt={logo} />
    <h2 className="heading">Support Dashboard</h2>
    {userInfo ? (
      <img
        src={userInfo.picture}
        height="50px"
        alt="user"
        style={{ marginRight: "20px" }}
      />
    ) : (
      <div></div>
    )}
  </div>
);
export default Header;
