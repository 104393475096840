import React, { Component } from "react";
import Chart from "./chart/Chart.js";
import "./Dashboard.css";
import OpenWOETable from "./openWoeTable/OpenWOETable.js";
import InpWOCTable from "./inpWocTable/InpWOCTable.js";
import OpenTable from "./openTable/OpenTable.js";
import OtherTable from "./otherTable/OtherTable.js";
import config from "../../resources/config/config.json";
import { withLoginState } from "../login/components/LoginState.js";
import { API } from "../../services/gitlab.js";

/**
 * @desc dashboard component is the parent component.It fetch the issues api and pass the data to child components
 using props.
 */
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      issuesDetails: [],
    };
  }
  /**
   * @desc refresh the parent and the child components by given time
   * @return {{}}
   */
  componentDidMount() {
    this.getIssuesData();
    setInterval(this.getIssuesData, config.refresh_time);
  }

  // fetch the issues api url and pass data as a state object
  getIssuesData = () => {
    const { api_link } = config;
    const { tokenData, renewToken } = this.props;
    const url = api_link + "issues?state=opened&per_page=100";
    const gitLabApi = API(url, tokenData.access_token, renewToken);
    gitLabApi
      .get()
      .then((res) => {
        const details = res.data;
        this.setState({ issuesDetails: details });
      })
      .catch((e) => {
        alert(
          "Unable to retrieve Issues details. Please refresh or contact the administrator. "
        );
      });
  };

  render() {
    const { issuesDetails } = this.state;
    return (
      <div className="dashboard-body">
        <div className="features">
          <div className="first-row">
            <div className="chart">
              <Chart />
            </div>
            <div className="open-table">
              <OpenTable table_issues_details={issuesDetails} />
            </div>
          </div>
          <div className="clear-fix" />
          <div className="second-row">
            <div className="open-woe-table">
              <OpenWOETable table_issues_details={issuesDetails} />
            </div>
            <div className="inp-woc-table">
              <InpWOCTable table_issues_details={issuesDetails} />
            </div>
          </div>
          <div className="third-row">
            <div className="other-table">
              <OtherTable table_issues_details={issuesDetails} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withLoginState(Dashboard);
