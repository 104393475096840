import moment from 'moment';

class Functions {
    /**
     * @desc formatDateTime - format the the given date and time
     * @param string string - to pass the argument
     * @return string - return the formatted date and time
     */
    static formatDateTime(string) {
        const options = {year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'};
        return new Date(string).toLocaleDateString([], options);
    };
    /**
     * @desc getDuration - calculate the duration using current time and the given time
     * @param  past_Time - to pass the argument
     * @return return the duration between two times
     */
    static getDuration(past_Time) {
        const different = moment(new Date(), "YYYY/MM/DD HH:mm:ss").diff(moment(past_Time, "YYYY/MM/DD HH:mm:ss"));
        const duration = moment.duration(different)
        return duration.asHours()
    };
    /**
     * @desc get_issues_data - common details which push to the list in every child components
     * @param  data - data is the object of the api list
     * @param  filtered_details = {} - filtered_data is the one create object using values with keys
     * @param  state - state is the state name which push to the list as a label key value
     * @return return the common data of the all child components
     */
    static get_issues_data(data, filtered_details, state) {
        filtered_details['id'] = data.id;
        filtered_details['url'] = data.web_url;
        filtered_details['iid'] = data.iid;
        filtered_details['customer_acc'] = data.web_url.split('/')[4];
        filtered_details['title'] = data.title;
        filtered_details['label'] = state;
        filtered_details['time'] = Functions.formatDateTime(data.updated_at);
        return filtered_details
    };
}

export default Functions;
