import React, { Component } from "react";
import Dashboard from "./dashboard/Dashboard";
import Login from "./login/scenes/Login";
import { Redirect, BrowserRouter, Route, Switch } from "react-router-dom";
import ProtectedRoute from "./login/components/PageProtector";
import LoginContext, { LoginProvider } from "./login/components/LoginState";
import config from "../resources/config/config.json";
import "./App.css";
import Header from "./header/Header";

class App extends Component {
  render() {
    return (
      <LoginProvider config={config.gitlab_oauth_config}>
        <LoginContext.Consumer>
          {({ userInfo }) => {
            return (
              <>
                <Header userInfo={userInfo} />
                <BrowserRouter>
                  <Switch>
                    <Redirect exact from="/" to="/dashboard" />
                    <Route path="/login">
                      <Login />
                    </Route>
                    <ProtectedRoute path="/dashboard">
                      <Dashboard />
                    </ProtectedRoute>
                  </Switch>
                </BrowserRouter>
              </>
            );
          }}
        </LoginContext.Consumer>
      </LoginProvider>
    );
  }
}

export default App;
