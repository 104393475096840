import React, {Component} from 'react';
import "./OpenTable.css";
import config from "../../../resources/config/config.json";
import Functions from "../../../functions/Functions";
import SearchTable from '../../search/SearchTable';

/**
 * @desc this component get the issues details from the parent component using props values and do the calculation
 and then fill the table with them.
 */
class OpenTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchInput: "",
        }
    }

    componentDidMount() {
        const {table_issues_details} = this.props
        const stateList = table_issues_details.map(detail => {
            return detail.state
        })
        this.setState({stateList})
    }

    onSearch = (e) => {
        this.setState({searchInput: e.target.value.toLowerCase()})
    }

    /**
     * @desc get the props value from the parent component and get the specific data and then pass them to the table
     * @return table values with the structure
     */
    getOpenTableData() {

        const {searchInput} = this.state;
        const {Open_table} = config;
        const table_list = [];
        const details = this.props.table_issues_details;

        details.forEach((detail) => {
            //this condition check the issues which has no assignee and the labels
            if (detail.labels.length === 0 && detail.title.toLowerCase().includes(searchInput)) {
                // let duration = Functions.getDuration(detail.updated_at);
                const filtered_data = {};
                // Filter out the issues which are older than the threshold value (i.e 48h) in the config (Open_table.open_time_duration)
                // if (duration <= Open_table.open_time_duration) {
                    // check, whether the each issue has a assignee or not
                    if (detail.assignee === null) {
                        filtered_data['assignee'] = Open_table.display_none_assignee;
                    } else {
                        filtered_data['assignee'] = detail.assignee.name;
                    }
                    table_list.push(Functions.get_issues_data(detail, filtered_data, Open_table.display_state_name));
                // }
            }
        });
        const rows = table_list.map((list) => (
            <tr key={list.id}>
                <td><a target="_blank" rel="noopener noreferrer" href={list.url}>{list.customer_acc} #{list.iid}</a></td>
                <td>{list.title}</td>
                <td>{list.label}</td>
                <td>{list.assignee}</td>
                <td>{list.time}</td>
            </tr>
        ));
        const table_structure = (
            <thead>
            <tr>
                <th>Issue</th>
                <th>Title</th>
                <th>State</th>
                <th>Assignee</th>
                <th>Update Time</th>
            </tr>
            </thead>
        );
        //check the list is empty or not, if its not the value will return to the table
        if (table_list.length !== 0) {
            return (
                <table>
                    {table_structure}
                    <tbody>{rows}</tbody>
                </table>
            );
        } else {
            return (
                <div>
                    <table>
                        {table_structure}
                    </table>
                    <div className="open-table-empty">
                    <h5>No Data</h5>
                    </div>
                </div>
            );
        }
    };

    render() {
        const {searchInput} = this.state;
        const openTableData = this.getOpenTableData();
        return (
            <div>
                <div className="open-table-title">
                    <h4>Open Issues</h4>
                </div>
                <SearchTable onSearch={this.onSearch} searchValue={searchInput} classNameList={["openTableSearch"]}/>
                <div className="openTable">{openTableData}</div>
            </div>
        );
    };
}

export default OpenTable;
