import React, { Component } from "react";
import { Doughnut } from "react-chartjs-2";
import "chartjs-plugin-labels";
import "./Chart.css";
import config from "../../../resources/config/config.json";
import { withLoginState } from "../../login/components/LoginState.js";
import { API } from "../../../services/gitlab";

/**
 * @desc this component is for show the details on the chart.  this component get the issues counts according to
 labels and the labels details.
 */
class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      all_opened_issues: 0,
      open_issues: 0,
      chartData: { chartData: null },
    };
  }
  /**
   * @desc refresh the parent and the child components by given time
   * @return {{}}
   */
  componentDidMount() {
    this.getLabelsData();
    setInterval(this.getLabelsData, config.refresh_time);
  }

  // this function for get the issues statistic counts
  getIssuesStatistics = (api_value, link_parameter = "") => {
    const { api_link } = config;
    const { tokenData, renewToken } = this.props;
    const url = api_link + "issues_statistics?" + link_parameter;
    const gitLabApi = API(url, tokenData.access_token, renewToken);
    gitLabApi
      .get()
      .then((res) => {
        this.setState({
          [api_value]: res.data.statistics.counts.opened,
        });
      })
      .catch(() => {
        alert(
          "Unable to retrieve count of issues.Please refresh or contact the administrator"
        );
      });
  };

  //this function fetch the data and then pass them to chart to show on the screen
  getLabelsData = () => {
    const { api_link, chart } = config;
    const { tokenData, renewToken } = this.props;
    const label_list = [];
    const value_list = [];
    const color_list = [];

    let total = 0;

    /* get the count of none label opened issues from the none label issues statistic api
        it gives the count of the open state issues which don't have labels  */
    this.getIssuesStatistics("open_issues", "labels=None&");
    /* get the count of opened issues from the issues statistic api
        it gives the count of the all opened issues  */
    this.getIssuesStatistics("all_opened_issues");
    const url = api_link + "labels?with_counts=true";
    const gitLabApi = API(url, tokenData.access_token, renewToken);

    //it gives the details of labels from labels api
    gitLabApi
      .get()
      .then((res) => {
        const labels_data = res.data;
        labels_data.forEach((label_data) => {
          chart.status.forEach((status_data) => {
            //check, whether the labels names are equal
            if (label_data.name === status_data.search_label) {
              label_list.push(status_data.show_label);
              value_list.push(label_data.open_issues_count);
              color_list.push(label_data.color);
            }
          });
          /* iterate the json search_label list of the other status object and get the values
                       this value is for calculate the other issues count  */
          chart.other_status.search_labels.forEach((search_label) => {
            if (label_data.name === search_label) {
              total += label_data.open_issues_count;
            }
          });
        });
        /* this condition gets the open issues count and push it to the list
                   check, the json value is true then the values will push to the list */
        if (chart.open_status.enable === true) {
          value_list.push(this.state.open_issues);
          label_list.push(chart.open_status.name);
          color_list.push(chart.open_status.color);
        }
        // other issues mean the issues with the label which in the open state
        if (chart.other_status.enable === true) {
          const other_count =
            this.state.all_opened_issues - this.state.open_issues - total;
          value_list.push(other_count);
          label_list.push(chart.other_status.name);
          color_list.push(chart.other_status.color);
        }

        //set the list data to chart
        this.setState({
          chartData: {
            labels: label_list,
            datasets: [
              {
                data: value_list,
                backgroundColor: color_list,
              },
            ],
          },
        });
      })
      .catch(() => {
        alert(
          "Calling label API failed.Please refresh or contact the administrator"
        );
      });
  };

  render() {
    const { all_opened_issues, chartData } = this.state;
    return (
      <div>
        <div className="total">
          <div>
            <b>Total Issues: {all_opened_issues}</b>
          </div>
        </div>
        <div className="chart">
          <Doughnut
            data={chartData}
            width={500}
            height={460}
            options={{
              responsive: false,
              maintainAspectoRatio: true,
              layout: {
                padding: {
                  top: 5,
                  left: 5,
                  right: 5,
                  bottom: 5,
                },
              },
              animation: {
                animateScale: true,
                animateRotate: true,
                duration: 3000,
              },
              plugins: {
                labels: {
                  render: "value",
                  fontSize: 18,
                  fontColor: "#000",
                },
              },
              legend: {
                display: true,
                position: "right",
                labels: {
                  hidden: "true",
                  fontSize: 14,
                  fontColor: "black",
                  usePointStyle: true,
                  padding: 12,
                },
                value: {
                  display: true,
                },
              },
            }}
          />
        </div>
      </div>
    );
  }
}

export default withLoginState(Chart);
